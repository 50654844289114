<script setup>
import { onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../assets/js/language'
import AboutBanner from './components/about-banner'

const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))

const checkedtype = ref(langvalue.value.Bus_introduction.three_2)
const type = ref(langtype)
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    checkedtype.value = returnLanguage(newVal).Bus_introduction.three_2
    type.value = newVal
  },
  { immediate: true }
)
</script>

<template>
  <div class="a_detail">
    <AboutBanner
      :checkedtype="checkedtype"
      title="Blockchain Research Institute"
    />
    <!-- <div class="center">
      <img
        v-if="langvalue.center.img == '10'"
        class="bg_img"
        src="../../assets/images/yw_10.png"
        alt=""
      />
      <img
        v-else-if="langvalue.center.img == '11'"
        class="bg_img"
        src="../../assets/images/yw_11.png"
        alt=""
      />
      <img v-else class="bg_img" src="../../assets/images/yw_12.png" alt="" />
    </div> -->

    <div class="main_box">
      <div class="title">
        <span class="line"></span>
        <span class="text">{{ langvalue.top.three }}</span>
        <span class="line"></span>
      </div>
      <div class="detail">
        <table>
          <thead>
            <tr>
              <th colspan="2">{{ langvalue.table_ins.title }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="label" width="240">{{ langvalue.table_ins.label }}</td>
              <td>{{ langvalue.table_ins.content }}</td>
            </tr>
            <tr>
              <td class="label" width="240">
                {{ langvalue.table_ins.label_1 }}
              </td>
              <td>{{ langvalue.table_ins.content_1 }}</td>
            </tr>
            <tr>
              <td class="label" width="240">
                {{ langvalue.table_ins.label_2 }}
              </td>
              <td>{{ langvalue.table_ins.content_2 }}</td>
            </tr>
            <tr>
              <td class="label" width="240">
                {{ langvalue.table_ins.label_3 }}
              </td>
              <td>{{ langvalue.table_ins.content_3 }}</td>
            </tr>
            <tr>
              <td class="label" width="240">
                {{ langvalue.table_ins.label_4 }}
              </td>
              <td>{{ langvalue.table_ins.content_4 }}</td>
            </tr>
            <tr>
              <td class="label" width="240">
                {{ langvalue.table_ins.label_5 }}
              </td>
              <td>{{ langvalue.table_ins.content_5 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="detail">
        <table>
          <thead>
            <tr>
              <th colspan="2">{{ langvalue.table_ins.title_1 }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="label" width="240">{{ langvalue.table_ins.label }}</td>
              <td>{{ langvalue.table_ins.content_6 }}</td>
            </tr>
            <tr>
              <td class="label" width="240">
                {{ langvalue.table_ins.label_2 }}
              </td>
              <td>{{ langvalue.table_ins.content_7 }}</td>
            </tr>
            <tr>
              <td class="label" width="240">
                {{ langvalue.table_ins.label_6 }}
              </td>
              <td>
                <p>
                  <span>{{ langvalue.table_ins.label_4 }}: </span>
                  {{ langvalue.table_ins.content_8
                  }}{{ langvalue.table_ins.content_9 }}
                </p>
                <p style="margin-top: 28px">
                  <span>{{ langvalue.table_ins.label_7 }}: </span>
                  {{ langvalue.table_ins.content_10 }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.a_detail {
  width: 100%;
  .title {
    font-size: 40px;
    font-weight: 400;
    color: #100e34;
    font-family: 'PingFang SC';

    display: flex;
    justify-content: center;
    align-items: center;
    .line {
      display: inline-block;
      width: 100px;
      height: 2px;
      background: #d3d5e0;
    }
    .text {
      margin: 0 20px;
    }
  }

  .center {
    box-sizing: border-box;
    background: #EAEBF0;
    display: flex;
    justify-content: center;
    .bg_img {
      // border: 1px solid red;
      width: 1240px;
      margin: 0 auto;
    }
  }

  .main_box {
    padding: 112px 120px 242px;
    background: #fafbff;

    .detail {
      margin-top: 93px;
      table {
        width: 100%;
        border-collapse: collapse;
        border-radius: 4px;
        border: 1px solid #dee0ff;
        background: #fff;
        thead {
          height: 95px;

          background: #f5f9fd;
          color: #34495e;
          font-family: 'PingFang SC';
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        tbody {
          border-radius: 2px;

          background: #fff;
          color: #34495e;
          font-family: 'PingFang SC';
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          tr {
            // height: 93px;

            td {

              border: none;
              border-bottom: 1px solid #dee0ff;
              padding: 40px 40px;

              &.label {
                text-align: center;
              }

              span {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .detail + .detail {
      margin-top: 120px;
    }
  }
}
</style>
